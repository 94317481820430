//Body
$body-min-width: 320px;
$body-background: #f6f6f6;
//Brand colors
$brand-primary: #00A1EF;
$brand-secondary: #A777F4;
$brand-third: #BEBEBE;
$theme-colors: (
  "primary": $brand-primary,
  "secondary": $brand-secondary,
  "third": $brand-third,
);
//Grid
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1324px
);
//TYPOGRAPHY
$font-family-primary: "Product Sans", "Arial", sans-serif;
$font-size-body: 24px;
$font-color-title: #575757;
$font-color-primary: #979797;
$link-color: #005f99;
$white: #FFFFFF;
// @Global text style
$typography_font__family: $font-family-primary;
$typography_font__size: $font-size-body;
$typography_line_height: 1.2;
$typography_text_color: $font-color-primary;
$typography_title_color: $font-color-title;
//Headers
$typography_headers__family: $typography_font__family;
$typography_headers__color: $typography_title_color;
$typography_headers__margin-top: #{$typography_line_height * 2}rem;
$typography_headers__margin-bottom: #{$typography_line_height + 0.2}rem;
$typography_headers__sizes: (
  h1: (
    xs: 25px,
    md: 30px,
    xl: 32px,
  ),
  h2: (
    xs: 21px,
    md: 27px,
    xl: 28px,
  ),
  h3: (
    xs: 19px,
    md: 22px,
    xl: 24px,
  ),
  h4: (
    xs: 17px,
    md: 20px,
    xl: $typography_font__size * 1.125,
  ),
  h5: (
    xs: 13px,
    md: 14px,
    xl: $typography_font__size,
  ),
  h6: (
    xs: 12px,
    md: 13px,
    xl: $typography_font__size * 0.9375,
  ),
);
// font weight
$typography_headers__weights: (
  h1: 700,
  h2: 600,
  h3: 500,
  h4: 400,
  h5: 400,
  h6: 400,
);
// Links
$typography_link__color: $link-color;
$typography_link__outline: none;
$typography_link__decoration: none;
$typography_link__hover-color: #0461a0;
$typography_link__hover-decoration: none;
$typography_link__active-color: lighten($typography_link__color, 5%);
$typography_link__focus-color: #005690;
// List
$typography_ol_li__border: map-get($theme-colors, primary);
$typography_ol_li__color: map-get($theme-colors, primary);
$typography_ul_li__bg: map-get($theme-colors, primary);
// Mark
$typography_mark__padding: 0 0.2em;
$typography_mark__color: $typography_text_color;
$typography_mark__bg-color: #feec91;
// PRE | CODE | KBD | SAMP
$typography_pre__bg-color: #f5f5f5;
$typography_pre__padding: 15px;
$typography_code__bg-color: #f5f5f5;
$typography_code__color: #e83e8c;
$typography_kbd__bg-color: #e1f0fa;
$typography_kbd__color: #000;
$typography_samp__bg-color: #f7f8e2;
// Table
$typography_table_border-color: #ccc;
$typography_th_color: #ddd;
$typography_th_td_padding: 10px;
$typography_tr-zebra_bg: #eee;
$typography_table__border-color: #ccc;
// Blockquote
$typography_blockquote__border-radius: 0;
$typography_blockquote__border-color: #0e76bc;
$typography_blockquote__padding: 15px;
// Bold and strong
$typography_bold_strong__color: inherit;
// u and ins
$typography_bold_strong__border-color: map-get($theme-colors, secondary);
// HR
$typography_hr__color: #000;
